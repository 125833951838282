$darkest-color: #211911;
$dark-color: #463625;
$light-color: #F9C6E0;
$lightest-color: #ffffff;
$primary-color: #EE6AAC;

$code-color-names: #DC474E;
$code-color-literals: #B2A7A2;
$code-color-builtins: #D8DEC6;
$code-color-keywords: #B03B50;
$code-color-comments: #8D7889;
$code-color-anything-else: $primary-color;

$avatar-bottom-offset: -8em;
$avatar-left-offset: -8em;

$break-width: 48em;
$big-break-width: 135em;
$desktop-sidebar-width: 12em;

@import 'base';
@import 'normalize';

@import 'components/code';
@import 'components/container';
@import 'components/header';
@import 'components/list';
@import 'components/post';
