* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: sans-serif;
  background: $darkest-color;
  color: $light-color;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  color: $lightest-color;
}

a {
  color: $primary-color;
  text-decoration: none;

  &:hover {
    color: $lightest-color;
  }
}

ol > li {
  list-style-type: hiragana-iroha;
}

ol, ul {
  padding-left: 1.5em;
}

pre {
  padding-top: 1em;
  padding-bottom: 1em;
  overflow: auto;
  max-width: 100vw;
}

code {
  background: $dark-color;
}

img {
  max-width: calc(100vw - 3em);
}

.task-list-item {
  visibility: hidden;
  width: 0;
  margin-right: 1em;
  &:before {
    visibility: visible;
    display: inline-block;
    content: "";
    width: 0.8em;
    height: 0.8em;
    margin-left: 0.2em;
    border-radius: 0.2em;
    border-color: $light-color;
    border-style: inset;
    border-width: 1px;

    background-color: $dark-color;
  }

  &:checked:before {
    // TODO: Add a checkmark if I can be bothered.
    background-color: $light-color;
  }
}

.spacer {
  flex-grow: 1;
}

.toggle {
  display: none;

  & + * {
    display: none;
  }

  &:checked + * {
    display: block;
  }
}

.contact-block {
  line-height: 0;
  text-align: right;
}

@media screen {
  .print-show {
    display: none;
  }
}

@media (min-width: $break-width) {
  body {
    flex-direction: row;
  }

  pre {
    // Screen width minus sidebar width, minus 1em each for left and
    // right padding on the content...  The minus 1 more mystery em.
    max-width: calc(100vw - #{$desktop-sidebar-width} - 3em);

    padding: 1em;
  }

  img {
    max-width: calc(100vw - #{$desktop-sidebar-width} - 3em);
    width: calc(64em - #{$desktop-sidebar-width} - 2em);
  }
}

@media (min-width: $big-break-width) {
  html {
    font-size: 2em;
  }

  pre {
    overflow: unset;
  }
}

@media print {
  @page {
    margin: 0;
  }

  p {
    text-align: justify;
    break-inside: avoid;
  }

  .contact-block {
    * {
      text-align: right;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    break-after: avoid;
  }

  .print-hide {
    display: none;
  }
}
