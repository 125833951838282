.app-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100vw;
  justify-content: center;
}

.app-container {
  padding-left: 1em;
  padding-right: 1em;

  // 4em for the header, and a mystery .5em?
  margin-top: 4.5em;

  @media print {
    margin-top: 0;
  }
}

.app-container.content {
  max-width: 100%;
}

@media (min-width: $break-width) {
  .app-container {
    margin-left: $desktop-sidebar-width;
    margin-top: 0;

    @media print {
      margin-left: 0;
    }
  }

  .app-container.content {
    @media screen {
      max-width: calc(64em - #{$desktop-sidebar-width});
      width: 64em;
    }
  }
}
