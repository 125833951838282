.post-header {
  display: flex;
  flex-direction: row;

  align-items: baseline;
  justify-content: space-between;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $light-color;

  & > time {
    white-space: nowrap;
  }
}
